import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import QRCode from 'qrcode.react';

const Home = () => {
    const url = 'https://play.google.com/store/apps/details?id=com.store.intimateApp&pcampaignid=web_share'
    return (
        <body>
            <div className="demo-banner py-3 text-white text-center fw-bold theme-bg-secondary text-white">Descarga la aplicación fácil y rápido ahora mismo.
                {/* <a classNameName="btn font-weight-bold ms-2 theme-btn-on-bg mt-2 mt-md-0" href="https://themes.3rdwavemedia.com/bootstrap-templates/mobile/bootstrap-template-for-mobile-apps-nova-pro/" target="_blank">
            <i classNameName="fas fa-external-link-alt me-2">
            </i>Check out <span classNameName="text-uppercase">Nova Pro</span>
            </a> */}
            </div>
            <header className="header">

                <div className="branding">

                    <div className="container position-relative">

                        <nav className="navbar navbar-expand-lg" >
                            <h1 className="site-logo"><a className="navbar-brand" href="/"><img className="logo-icon" src="./images/logos/home.png" height={50} alt="logo" /> <span className="logo-text">Intimate.App <span className="alt rounded">Pro</span></span></a></h1>


                            {/* <ul className="social-list list-inline mb-0 position-absolute">
                                <li className="list-inline-item"><a className="text-dark" href="https://www.instagram.com/intimate.company"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                </svg></a></li>
                            </ul> */}
                        </nav>
                        {/* <ul className="social-list list-inline mb-0 position-absolute d-none">
                            <li className="list-inline-item"><a className="text-dark" href=""><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                            </svg></a></li>
                        </ul> */}
                    </div>
                </div>
            </header>


            <section className="hero-section">
                <div className="container">
                    <div className="row figure-holder">
                        <div className="col-12 col-md-6 pt-3 pt-md-4">
                            <h2 className="site-headline font-weight-bold mt-lg-5 pt-lg-5">Bienvenido a tu espacio!</h2>
                            <div className="site-tagline mb-3">Descubre este maravilloso universo. Haz click o escanea el QR para Descargar la Aplicación, y comenzá a disfruar ahora!</div>
                            <div className="cta-btns">
                                <div className="mb-4">
                                    <div className='scanQr'>
                                        <QRCode className='qr' value={url} />
                                    </div>
                                    {/* <a className="btn btn-primary font-weight-bold theme-btn" href="https://themes.3rdwavemedia.com/bootstrap-templates/startup/nova-bootstrap-landing-page-template-for-mobile-apps/">Try Nova for FREE</a>
                                 */}
                                </div>
                                <ul className="app-stores list-unstyled list-inline mx-auto mx-md-0 d-inline-block">
                                    {/* <li className="list-inline-item me-3"><a href="#"><img className="ios" src="./images/appstore-apple.svg" alt="app-store" /></a></li> */}
                                    <li className="list-inline-item"><a href="https://play.google.com/store/apps/details?id=com.store.intimateApp&pcampaignid=web_share"><img className="android" src="./images/appstore-android.svg" alt="google play" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="logos-section theme-bg-primary py-5 text-center">
                <div>
                    <h3 className="mb-5">Empresas que confian en nosotros.</h3>
                    <div className="logos-row row mx-auto centerlogos">
                        <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/paypal-logo.png" alt="logo" />
                            </div>
                        </div>
                        <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/android-logo.png" alt="logo" />
                            </div>
                        </div>
                        <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/playstore-logo.png" alt="logo" />
                            </div>
                        </div>
                        {/* <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/logo-4.svg" alt="logo" />
                            </div>
                        </div>
                        <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/logo-5.svg" alt="logo" />
                            </div>
                        </div>
                        <div className="item col-6 col-md-4 col-lg-2 mb-3 mb-lg-0">
                            <div className="item-inner">
                                <img src="./images/logos/logo-6.svg" alt="logo" />
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>

            <section className="benefits-section py-5">

                <div className="container py-lg-5">
                    <h3 className="mb-5 text-center font-weight-bold">Un poco de nuestra aplicación.</h3>
                    <div className="row">
                        <div className="item col-12 col-lg-4">
                            <div className="item-inner text-center p-3 p-lg-5">
                                <img className="mb-3" src="./images/icon-target.svg" alt="" />
                                <h5>Centrado en UX</h5>
                                <div>Experimenta una UX sin igual con nuestra aplicación: navegación intuitiva, acceso rápido a contenido exclusivo y una interfaz diseñada para brindarte una experiencia personalizada e inmersiva. Descarga ahora y descubre el placer con facilidad.</div>
                            </div>
                        </div>
                        <div className="item col-12 col-lg-4">
                            <div className="item-inner text-center p-3 p-lg-5">
                                <img className="mb-3" src="./images/icon-rocket.svg" alt="" />
                                <h5>Usarios</h5>
                                <div>Descarga nuestra aplicación para acceder a contenido exclusivo, conectarte con una comunidad afín y disfrutar de una experiencia íntima personalizada. Privacidad garantizada, navegación intuitiva y placer sin restricciones te esperan. </div>
                            </div>
                        </div>
                        <div className="item col-12 col-lg-4">
                            <div className="item-inner text-center p-3 p-lg-5">
                                <img className="mb-3" src="./images/icon-cogs.svg" alt="" />
                                <h5>Personalizaciones sencillas</h5>
                                <div>Configurar nuestra aplicación es tan sencillo como un clic. Personaliza tus preferencias de manera rápida e intuitiva, sin complicaciones.</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="pt-3 text-center">
                        <a className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold" href="#">Learn More</a>
                    </div> */}
                </div>

            </section>

            {/* <section className="features-section py-5">
                <div className="container py-lg-5 position-relative">
                    <h3 className="mb-3 text-center font-weight-bold section-heading">Feature Highlights</h3>
                    <div className="row pt-5 mb-5">

                        <div className="col-12 col-md-6 col-xl-5 offset-xl-1 d-none d-md-block">
                            <img className="product-figure product-figure-1 img-fluid" src="./images/product-figure-1.png" alt="" />
                        </div>

                        <div className="col-12 col-md-6 col-xl-5 pr-xl-3 pt-md-3">
                            <div className="card rounded border-0 shadow-lg  mb-5">
                                <div className="card-body p-4">
                                    <h5 className="card-title"><i className="far fa-chart-bar me-2 me-lg-3 text-primary fa-lg fa-fw"></i>Feature Lorem Ipsum</h5>
                                    <p className="card-text">List one of your product's main features here. The screenshots used in this template are taken from Bootstrap admin template Appify. </p>
                                    <a className="more-link" href="#" >Learn more <span className="more-arrow">&rarr;</span></a>
                                </div>
                            </div>

                            <div className="card rounded border-0 shadow-lg mb-5">
                                <div className="card-body p-4">
                                    <h5 className="card-title"><i className="fas fa-laptop-code me-2 me-lg-3 text-primary fa-lg fa-fw"></i>Feature Consectetuer</h5>
                                    <p className="card-text">List one of your product's main features here. The screenshots used in this template are taken from Bootstrap admin template Appify.</p>
                                    <a className="more-link" href="#" >Learn more <span className="more-arrow">&rarr;</span></a>
                                </div>
                            </div>
                            <div className="card rounded border-0 shadow-lg">
                                <div className="card-body p-4">
                                    <h5 className="card-title"><i className="far fa-calendar-alt me-2 me-lg-3 text-primary fa-lg fa-fw"></i>Feature Lorem Ipsum</h5>
                                    <p className="card-text">List one of your product's main features here. The screenshots used in this template are taken from Bootstrap admin template Appify.</p>
                                    <a className="more-link" href="#" >Learn more <span className="more-arrow">&rarr;</span></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6 col-xl-5 order-md-2 pr-xl-3 d-none d-md-block">
                            <img className="product-figure product-figure-2 img-fluid" src="./images/product-figure-2.png" alt="" />
                        </div>
                        <div className="col-12 col-md-6 col-xl-5 order-md-1 offset-xl-1 pt-xl-5">
                            <div className="card rounded border-0 shadow-lg  mb-5">
                                <div className="card-body p-4">
                                    <h5 className="card-title"><i className="fas fa-microphone-alt me-2 me-lg-3 text-primary fa-lg fa-fw"></i>Feature Commodo</h5>
                                    <p className="card-text">List one of your product's main features here. The screenshots used in this template are taken from Bootstrap admin template Appify. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. </p>
                                    <a className="more-link" href="#" >Learn more <span className="more-arrow">&rarr;</span></a>
                                </div>
                            </div>

                            <div className="card rounded border-0 shadow-lg">
                                <div className="card-body p-4">
                                    <h5 className="card-title"><i className="far fa-comments me-2 me-lg-3 text-primary fa-lg fa-fw"></i>Feature  Ligula Eget</h5>
                                    <p className="card-text">List one of your product's main features here. Lorem ipsum dolor sit amet. The screenshots used in this template are taken from Bootstrap admin template Appify.</p>
                                    <a className="more-link" href="#" >Learn more <span className="more-arrow">&rarr;</span></a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="pt-5 text-center">
                        <a className="btn btn-primary theme-btn theme-btn-ghost font-weight-bold" href="#">View all features</a>
                    </div>
                </div>
            </section> */}

            <footer className="footer theme-bg-primary">
                <div className="container py-5 mb-3">
                    <div className="row">
                        {/* <div className="footer-col col-6 col-lg-3">
                            <h4 className="col-heading">About Nova</h4>
                            <ul className="list-unstyled">
                                <li><a className="text-link" href="#">Our Story</a></li>
                                <li><a className="text-link" href="#">Pricing</a></li>
                                <li><a className="text-link" href="#">Contact</a></li>
                                <li><a className="text-link" href="#">Jobs</a></li>
                            </ul>
                        </div>
                        <div className="footer-col col-6 col-lg-3">
                            <h4 className="col-heading">Resources</h4>
                            <ul className="list-unstyled">
                                <li><a className="text-link" href="#">FAQs</a></li>
                                <li><a className="text-link" href="#">Blog</a></li>
                                <li><a className="text-link" href="#">Support</a></li>
                                <li><a className="text-link" href="#">Developer APIs</a></li>
                            </ul>
                        </div> */}
                        <div className="footer-col col-6 col-lg-3">
                            <h4 className="col-heading">Contáctate con nosotros.</h4>
                            <ul className="social-list list-unstyled mb-0">
                                <li className="list-inline-item"><a href="https://www.instagram.com/intimate.company"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16">
                                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334" />
                                </svg></a> <a className='ig'> Seguinos en instagram!</a></li>
                            </ul>

                            <div className="mb-2">
                                Intimate.App<br />
                                Ciudad Autonoma de Buenos Aires <br />
                                Buenos Aires, Argentina
                            </div>
                            <div>
                                <a className="text-link" href="#">help@intimate.app.com</a>
                            </div>

                        </div>
                        <div className="footer-col col-6 col-lg-3">
                            <h4 className="col-heading">Legal</h4>
                            <ul className="list-unstyled">
                                <li><NavLink to="/privacy">Privacidad</NavLink></li>
                                <li><NavLink to="/terms_of_services">Terminos y condiciones</NavLink></li>
                                <li><NavLink to="/security">Seguridad</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <hr />
                </div>
                <div className="download-area py-4">
                    <div className="container text-center">
                        <h3 className="mb-3">Obtener la aplicación</h3>
                        <div className="section-intro mb-4 single-col-max-width mx-auto">Descarga nuestras aplicación ahora.</div>
                        <ul className="app-stores list-unstyled list-inline mx-auto  d-inline-block">
                            {/* <li className="list-inline-item me-3"><a href="#"><img className="ios" src="./images/appstore-apple.svg" alt="app-store" /></a></li> */}
                            <li className="list-inline-item"><a href="#"><img className="android" src="./images/appstore-android.svg" alt="google play" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom text-center pb-5">
                    <small className="copyright">© Derechos Reservados Intimate.App.</small>
                </div>

            </footer>

        </body>
    )
}


export default Home