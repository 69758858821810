import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from "react";

const Payment = () => {
    const navigate = useNavigate()
    const [price, setPrice] = useState("")

    const toastOptions = {
        position: 'top-center',
        autoClose: 1000,
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        const statusParam = url.search.substring(2)
        setPrice(statusParam)
    }, []);


    const initialOptions = {
        clientId: "Af74x595O5OblEt7ds9D5XjjKUHMtlE09spX3fJSnSAOI7mKGLcjyjJn5CQPM2OL3MndMaJuWVHvfTAE",
        currency: "USD",
        intent: "capture",
        'data-csp-nonce': 'YOUR-CSP-NONCE'
    };

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: price,
                    },
                },
            ],
            application_context: {
                user_action: 'PAY_NOW',
                shipping_preference: 'NO_SHIPPING',
            },
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            window.location.href = '/success_payment'
        });
    };

    const onError = (err) => {
        toast.error("Error: " + err, toastOptions);
    };

    const onCancel = () => {
        toast.warn('Transacción cancelada', toastOptions);
    };

    return (
        <div className="btnPaypal">
            <div className="containerPrice">
                <div className="titlePrice"><h1>Intimate.App</h1></div>

                <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                    <div class="p-4" id="rightPanel">
                        <h6 class="text-uppercase"><small>Subscribe To</small></h6>
                        <h4 class="boldFont">Membership</h4>

                        <div class="my-4 d-flex justify-content-between align-items-center">
                            <div>Subscription<br /><span class="text-secondary"><small>Billed Yearly</small></span></div>
                            <div class="w-100 mx-3 borderDotted"></div>
                            <div>{price}usd</div>
                        </div>
                        <div class="my-4 d-flex justify-content-between align-items-center">
                            <div >Application Fee<br /><span class="text-secondary"><small>1 time only</small></span></div>
                            <div class="w-100 mx-3 borderDotted"></div>
                            <div>0usd</div>
                        </div>
                        <div class="my-4">
                            <h6>Apply discount code</h6>
                            <div class="my-3">
                                <input type="text" class="bg-white form-control" placeholder="Code" />
                            </div>
                        </div>
                        <hr />
                        <div class="my-4 fs-4">
                            <div class="d-flex justify-content-between">
                                <div class="">Total</div>
                                <div class="text-primary">{price}usd</div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                            {/* <div><input type="checkbox" id="agreeTerms"/></div> */}
                            <div class="mx-4"><label for="agreeTerms"><small>I agree to the <a href="#">Terms and Conditions</a> and the <a href="#">Automatic Renewal Terms</a> above</small></label></div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="containerBtn">
                <PayPalScriptProvider options={initialOptions}>
                    <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onError={onError}
                        onCancel={onCancel}
                    />
                </PayPalScriptProvider>
                <ToastContainer position="top-center" autoClose={1000} />
            </div>
        </div>
    );
}

export default Payment