import React from 'react';
import Home from '../components/Home';

class Landing extends React.Component {
    render() {
        return (
            <>
                <Home />
            </>
        )
    }
}

export default Landing