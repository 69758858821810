import React from 'react';
import { NavLink } from 'react-router-dom';

class TermsOfServices extends React.Component {
    render() {
        return (
            <>
                <div className="demo-banner py-3 text-white text-center fw-bold theme-bg-secondary text-white">
                    <h4>Terminos y condiciones.</h4>
                    <button type="button" class="btn"><NavLink to="/">{"<- Volver"}</NavLink></button>
                </div>
                <div className='legals'>
                    <div className='textLegals'>
                        <ol>
                            <li>Contenido de la Aplicación:
                                <ul>
                                    <li>Intimate.app es una plataforma que permite a los usuarios compartir y acceder a contenido exclusivo para adultos. Todo el contenido debe cumplir con nuestras políticas y directrices, y nos reservamos el derecho de eliminar cualquier material que consideremos inapropiado.</li>
                                </ul>
                            </li>
                            <li>Usuarios:
                                <ul>
                                    <li>Debes tener al menos 18 años para utilizar Intimate.App.</li>
                                    <li>Proporcionar información falsa o utilizar la aplicación de manera fraudulenta resultará en la suspensión inmediata de tu cuenta.</li>
                                </ul>
                            </li>
                            <li>Privacidad:
                                <ul>
                                    <li>Respetamos tu privacidad. Consulta nuestra política de privacidad para obtener información detallada sobre cómo manejamos tus datos personales.</li>
                                </ul>
                            </li>
                            <li>Pagos:
                                <ul>
                                    <li>Intimate.App utiliza un sistema de pago seguro para las transacciones.</li>
                                    <li>Al realizar pagos, aceptas cumplir con los términos y condiciones del proveedor de servicios de pago.</li>
                                </ul>
                            </li>
                            <li>Conducta del Usuario:
                                <ul>
                                    <li>No se tolerará el acoso, la discriminación o cualquier forma de comportamiento ofensivo hacia otros usuarios.</li>
                                    <li>No está permitido compartir información personal de otros usuarios sin su consentimiento.</li>
                                </ul>
                            </li>
                            <li>Propiedad Intelectual:
                                <ul>
                                    <li>El contenido compartido en Intimate.App está protegido por derechos de autor.</li>
                                    <li>No se permite la reproducción o distribución no autorizada del contenido.</li>
                                </ul>
                            </li>
                            <li>Suspensión y Terminación:
                                <ul>
                                    <li>Nos reservamos el derecho de suspender o cerrar cuentas que violen nuestros términos y condiciones sin previo aviso.</li>
                                </ul>
                            </li>
                            <li>Modificaciones:
                                <ul>
                                    <li>Podemos actualizar estos términos en cualquier momento.</li>
                                    <li>Te notificaremos sobre cambios significativos, pero es tu responsabilidad revisar periódicamente estos términos.</li>
                                </ul>
                            </li>
                            <li>Ley Aplicable:
                                <ul>
                                    <li>Estos términos se rigen por las leyes del Argentina y cualquier disputa estará sujeta a la jurisdicción de los tribunales competentes de Buenos Aires.</li>
                                </ul>
                            </li>
                            <li>Al utilizar Intimate.App, aceptas estos términos y condiciones. Si tienes alguna pregunta o inquietud, no dudes en ponerte en contacto con nuestro equipo de soporte.</li>
                        </ol>
                        <p>¡Disfruta de Intimate.App de manera responsable!</p>
                    </div>
                </div>
            </>
        )
    }
}

export default TermsOfServices