import React from 'react';
import { NavLink } from 'react-router-dom';

class Privacy extends React.Component {
    render() {
        return (
            <>
                <div className="demo-banner py-3 text-white text-center fw-bold theme-bg-secondary text-white">
                    <h4>Privacidad.</h4>
                    <button type="button" class="btn"><NavLink to="/">{"<- Volver"}</NavLink></button>
                </div>
                <div className='legals'>
                    <div className='textLegals'>
                        <ol>
                            <li>Elegibilidad:
                                <ul>
                                    <li>Debes tener al menos 18 años para crear una cuenta en Intimate.App.</li>
                                    <li>Proporcionar información falsa o inexacta puede resultar en la suspensión inmediata de tu cuenta.</li>
                                </ul>
                            </li>

                            <li>Contenido Compartido:
                                <ul>
                                    <li>Todo el contenido compartido en Intimate.App debe cumplir con nuestras políticas y directrices. Nos reservamos el derecho de eliminar cualquier contenido que consideremos inapropiado o que viole nuestras normas.</li>
                                </ul>
                            </li>

                            <li>Privacidad:
                                <ul>
                                    <li>Respetamos tu privacidad. Consulta nuestra política de privacidad para obtener detalles sobre cómo manejamos y protegemos tus datos personales.</li>
                                </ul>
                            </li>

                            <li>Responsabilidad del Usuario:
                                <ul>
                                    <li>Eres responsable de mantener la confidencialidad de tu contraseña y de cualquier actividad que ocurra en tu cuenta.</li>
                                </ul>
                            </li>

                            <li>Pagos:
                                <ul>
                                    <li>Al utilizar servicios de pago en Intimate.App, aceptas cumplir con los términos y condiciones del proveedor de servicios de pago.</li>
                                </ul>
                            </li>

                            <li>Cancelación de Cuenta:
                                <ul>
                                    <li>Puedes cancelar tu cuenta en cualquier momento. Consulta nuestra sección de soporte para obtener instrucciones detalladas sobre cómo hacerlo.</li>
                                </ul>
                            </li>

                            <li>Conducta del Usuario:
                                <ul>
                                    <li>No se tolerará el acoso, la discriminación o cualquier forma de comportamiento ofensivo hacia otros usuarios.</li>
                                </ul>
                            </li>

                            <li>Modificaciones en los Términos:
                                <ul>
                                    <li>Nos reservamos el derecho de modificar estos términos en cualquier momento. Te notificaremos sobre cambios significativos.</li>
                                </ul>
                            </li>

                            <li>Ley Aplicable:
                                <ul>
                                    <li>Estos términos se rigen por las leyes de Argentina y cualquier disputa estará sujeta a la jurisdicción de los tribunales competentes de Buenos Aires.</li>
                                </ul>
                            </li>
                        </ol>
                        <p>Al crear una cuenta en Intimate.App, aceptas estos términos y condiciones. Si tienes preguntas o inquietudes, no dudes en comunicarte con nuestro equipo de soporte.</p>
                        <p>¡Gracias por ser parte de Intimate.App!</p>
                    </div>
                </div>
            </>
        )
    }
}

export default Privacy