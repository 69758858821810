import './App.css';
import './Home.css';
import './Flicki.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import Payment from './pages/Payment';
import PaymentSuccess from './pages/PaymentSuccess';
import Privacy from './pages/Privacy';
import Security from './pages/Security';
import TermsOfServices from './pages/TermsOfServices';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/success_payment" element={<PaymentSuccess />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/security" element={<Security />} />
        <Route path="/terms_of_services" element={<TermsOfServices />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;