import React from 'react';
import { NavLink } from 'react-router-dom';

class Security extends React.Component {
    render() {
        return (
            <>
                <div className="demo-banner py-3 text-white text-center fw-bold theme-bg-secondary text-white">
                    <h4>Seguridad.</h4>
                    <button type="button" class="btn"><NavLink to="/">{"<- Volver"}</NavLink></button>
                </div>
                <div className='legals'>
                    <div className='textLegals'>
                        <ol>
                            <li>Aceptación de los Términos:
                                <p>Al utilizar nuestra pasarela de pagos, aceptas cumplir con estos términos y condiciones. Si no estás de acuerdo con alguna parte de estos términos, te instamos a que no utilices nuestros servicios de pago.</p>
                            </li>

                            <li>Información de la Cuenta:
                                <p>Debes proporcionar información precisa y actualizada al realizar transacciones a través de nuestra pasarela de pagos. Nos reservamos el derecho de suspender o cancelar tu cuenta si la información proporcionada es incorrecta o incompleta.</p>
                            </li>

                            <li>Seguridad de la Cuenta:
                                <p>Eres responsable de mantener la confidencialidad de tu información de cuenta, incluidas las contraseñas asociadas. Cualquier actividad realizada desde tu cuenta se considerará como realizada por ti, a menos que notifiques de manera inmediata cualquier actividad no autorizada.</p>
                            </li>

                            <li>Transacciones:
                                <p>Todas las transacciones realizadas a través de nuestra pasarela de pagos están sujetas a verificación y autorización. Nos reservamos el derecho de rechazar transacciones por cualquier motivo, incluyendo, pero no limitado a, fraudes, sospechas de actividad ilegal o violación de estos términos.</p>
                            </li>

                            <li>Tarifas y Cargos:
                                <p>Al utilizar nuestra pasarela de pagos, aceptas pagar todas las tarifas y cargos asociados con las transacciones. Las tarifas están sujetas a cambios, y te notificaremos de cualquier modificación con anticipación.</p>
                            </li>

                            <li>Cancelaciones y Reembolsos:
                                <p>Las cancelaciones y los reembolsos están sujetos a nuestras políticas individuales y a las políticas de los proveedores de servicios. Te recomendamos revisar detenidamente estas políticas antes de realizar cualquier transacción.</p>
                            </li>

                            <li>Privacidad y Seguridad:
                                <p>Nos comprometemos a proteger tu privacidad y la seguridad de tu información. Consulta nuestra política de privacidad para obtener más detalles sobre cómo manejamos tus datos.</p>
                            </li>

                            <li>Modificaciones de los Términos:
                                <p>Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento. Te notificaremos de cualquier cambio mediante la publicación de la versión actualizada en nuestra aplicación.</p>
                            </li>
                        </ol>
                        <p>Al utilizar nuestra pasarela de pagos, reconoces que has leído, comprendido y aceptado estos términos y condiciones. Si tienes alguna pregunta, no dudes en ponerte en contacto con nuestro servicio de atención al cliente. ¡Gracias por confiar en nosotros!</p>
                    </div>
                </div>
            </>
        )
    }
}

export default Security