import React from 'react';

class PaymentSuccess extends React.Component {
    render() {
        return (
            <>
                <div className='success'>
                    <img className='imgSuccess' src="/images/success.svg" alt="" />
                    <h2>¡Gracias por su compra!❤️</h2>
                    <p>Espero que podamos volver a vernos pronto...
                        😊</p>
                </div>
            </>
        )
    }
}

export default PaymentSuccess